<template>
  <div class="wrapper" style="margin-top:5%">
    <b-container>
      <b-row>
        <b-col>
          <p class="TextBlack32">{{ $t("taklifedit") }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6" lg="6" class="mt-4">
          <div>
            <b-form-input
              class="inputTaklif"
              v-model="Offer.senderfio"
              :placeholder="$t('senderfio')"
            ></b-form-input>
          </div>
        </b-col>
        <b-col sm="12" md="6" lg="6" class="mt-4">
          <div>
            <b-form-input
              class="inputTaklif"
              v-mask="'+998##-###-##-##'"
              :placeholder="$t('+9989*-***-**-**')"
              v-model="Offer.senderphone"
            ></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6" lg="6" class="mt-4">
          <div>
            <b-form-input
              class="inputTaklif"
              v-model="Offer.senderemail"
              :placeholder="$t('mail')"
            ></b-form-input>
          </div>
        </b-col>
        <b-col sm="12" md="6" lg="6" class="mt-4">
          <div>
            <!-- <b-form-select
              class="inputTaklif"
              :options="OblastList"
              v-model="Offer.oblastid"
              text-field="name"
              @change="OblastChange"
            >
              <b-form-select-option>{{
                $t("adress")
              }}</b-form-select-option></b-form-select
            > -->
            <custom-select
             class="inputTaklif"
              :options="OblastList"
              v-model="Offer.oblastid"
              
            ></custom-select>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-4">
          <div>
            <b-form-textarea
              class="inputTaklif"
              v-model="Offer.text"
              rows="8"
              max-rows="16"
              :placeholder="$t('Таклиф')"
            ></b-form-textarea>
          </div>
        </b-col>
      </b-row>

      <div class="m-2">
        <b-row>
          <b-col class="text-left">
            <b-button
              v-if="!SaveLoading"
              @click="Back"
              class="buttonTaklif"
            >
              <b-spinner v-if="SaveLoading" small></b-spinner>
              <p class="m-2 pl-2 pr-2">{{ $t("Back") }}</p></b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              v-if="!SaveLoading"
              @click="SaveData"
              class="buttonTaklif"
            >
              <b-spinner v-if="SaveLoading" small></b-spinner>
              <p class="m-2 pl-2 pr-2">{{ $t("send") }}</p></b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
import OfferService from "@/services/Offer.service";
import RegionService from "@/services/Region.service";
import CustomSelect from "../../layout/customSelect.vue";
import OblastService from "@/services/Oblast.service";
import ServiceService from "@/services/Service.service";
export default {
  components: { CustomSelect },
  data() {
    return {
      Offer: {
        senderphone: "+998",
      },
      regionlist: [],
      OblastList: [],
      DownloadLoading: false,
      SaveLoading: false,
      lang: "",
    };
  },
  created() {
    this.lang = localStorage.getItem("locale") || "ru";
    OfferService.Get(this.$route.params.id).then((res) => {
      this.Offer = res.data;
    });
    // RegionService.GetAll().then((res) => {
    //   this.regionlist = res.data;
    // });
    OblastService.GetAll(this.lang).then((res) => {
      this.OblastList = res.data;
    });
    // ServiceService.GetAll().then((res) => {
    //   res.servicelist = res.data;
    // });
  },
  methods: {
    Back(){
      this.$router.push({name: 'taklif'})
    },
    OblastChange() {
      this.Offer.oblastname = this.OblastList.filter(
        (item) => item.id == this.Offer.oblastid
      )[0].name;
    },

    makeToast(message, title, type) {
      this.toastCount++;
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 2000,
        variant: type,
        solid: true,
      });
    },
    SaveData() {
      OfferService.Update(this.Offer)
        .then((res) => {
          // this.makeToast(
          //   this.$t("SuccessMessage"),
          //   this.$t("message"),
          //   "success"
          // );
          this.$router.push({
            name: "taklif",
          });
        })
        .catch((error) => {
          this.makeToast(
            error.response.data.error,
            this.$t("message"),
            "danger"
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped>
$color: #eeee;
</style>
